import type { RouteMatchCallback, Route as IRoute, RouteHandler, HTTPMethod, RouteHandlerObject } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

export interface IFileInfo {
    importMapEnty: IServiceWorkerImportMapEntry;
    parentFileIdentifier: string | undefined;
    childFileIdentifiers: Array<TFileIdentifier>;
    appIdentifiers: Set<TAppIdentifier>;
    routeIdentifiers: Array<TRouteIdentifier>;
    defaultRouteIdentifier: TDefaultRouteIdentifier | undefined;
    exportObject: any;
}

export interface IRouteInfo {
    route: IRoute;
    appIdentifiers: Set<TAppIdentifier>;
    fileIdentifier: TFileIdentifier;
}

export interface IDefaultRouteInfo {
    handler: RouteHandlerObject;
    appIdentifiers: Set<TAppIdentifier>;
    fileIdentifier: TFileIdentifier;
}

export interface IAppInfo {
    fileIdentifiers: Array<TFileIdentifier>;
    routeIdentifiers: Array<TRouteIdentifier>;
    defaultRouteIdentifier: TDefaultRouteIdentifier | undefined;
    installed: boolean;
}

export interface IIDBTransactionSuccessResult<T = any> {
    success: true;
    requestResults: Array<IIDBRequestSuccessResult<T>>;
}

export interface IIDBTransactionErrorResult<T = any> {
    success: false;
    requestResults: Array<IIDBRequestResult<T>>;
    error: DOMException | null;
}

export interface IIDBRequestSuccessResult<T = any> {
    success: true;
    result: T;
}

export interface IIDBRequestErrorResult {
    success: false;
    error: DOMException | null;
}

export type IIDBTransactionResult<T = any> = IIDBTransactionSuccessResult<T> | IIDBTransactionErrorResult<T>;
export type IIDBRequestResult<T = any> = IIDBRequestSuccessResult<T> | IIDBRequestErrorResult;

export type TAppIdentifier = string;
export type TFileIdentifier = string;
export type TRouteIdentifier = string;
export type TDefaultRouteIdentifier = string;

export interface IO365 {
    get cdnUrl(): string;

    get currentAppIdentifier(): TAppIdentifier | undefined;
    get currentFileIdentifier(): TFileIdentifier | undefined;
    get currentParentFileIdentifier(): TFileIdentifier | undefined;

    get logger(): Console;

    registerRoute: (capture: RegExp | string | RouteMatchCallback | IRoute, handler?: RouteHandler, method?: HTTPMethod) => void
    registerDefaultRoute: (handler: RouteHandlerObject) => void
    
    importScripts: <T>(url: string | URL, fileInfo?: IServiceWorkerImportMapEntry) => T
    exportScripts: <T>(exportObject: T) => void

    getImportMapEntry: (fileIdentifier: TFileIdentifier) => IServiceWorkerImportMapEntry | undefined;
    getImportMapEntryFromImportUrl: (url: string | URL) => IServiceWorkerImportMapEntry;
}
